.pan_search_fetch_div {
  width: 100%;
  /* height: 12.125rem; */
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  opacity: 1;
  /* padding-bottom: 1.5rem; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 99;
}

.pan_searchInput_fetchButton {
  max-width: 64rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pan_details_1024 {
  max-width: 64rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.pan_details_block {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: auto;
}

.GST_details_div {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0px;
}

.pan_input_div {
  display: flex;
  position: relative;
  /* margin-right: 1.25rem; */
}

.pan_input_div > input {
  /* width: 54.75rem; */
  /* height: 2.25rem; */
  background: var(--Blue_4) 0% 0% no-repeat padding-box;
  /* border-radius: 0.625rem; */
  opacity: 1;
  padding-left: 3rem;
  text-align: left;
  font: var(--Font_12) normal var(--Font_normal) 0.75rem/1.021rem Open Sans;
  letter-spacing: 0px;
  outline: none;
}

.pan_input_div > img {
  width: 1rem;
  height: 1rem;
  position: absolute;
  /* right: 1.25rem; */
  /* top: 0.625rem; */
  /* bottom: 0.625rem; */
  cursor: pointer;
  margin-left: 20px;
}

.pan_cancel {
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 1.25rem;
  /* top: 0.625rem; */
  /* bottom: 0.625rem; */
  cursor: pointer;
  margin-left: 20px;
}

.pan_fetch_button {
  /* width: 8rem !important; */
  /* height: 2.25rem !important; */
  /* border-radius: 0.625rem !important; */
  text-align: center !important;
  font: var(--Font_14) normal var(--Font_semibold) 0.875rem/1.1875rem Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

.GST_details_1024 {
  max-width: 64rem;
  width: 64rem;
}

.associated_GSTs_button {
  /* width: 7.125rem;
  height: 1.688rem; */
  /* background-color: var(--Primary_white); */
  margin-bottom: 1.25rem;
  /* border-radius: 1.25rem; */
  /* padding: 0.625rem; */
}

.cin_details_pan {
  width: 100%;
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  opacity: 1;
  justify-content: center;
  align-items: center;
}

.update_details_block {
  display: flex;
}

.pan_gst_have {
  display: flex;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
  height: fit-content;
  column-gap: 20px;
  row-gap: 20px;
}

.pan_gst_have_1:hover {
  background: var(--Blue_5_hover) 0% 0% no-repeat padding-box;
}

.pan_gst_have_1 {
  /* width: 20.5rem; */
  background: var(--Blue_5) 0% 0% no-repeat padding-box;
  border-radius: 1.25rem;
  opacity: 1;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.pan_gst_have_1 > img {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.pan_cta_button {
  width: 12.5rem;
  height: 36px;
  background-color: var(--Blue_8);
  color: var(--Primary_white);
  font-size: var(--Font_12);
  font-weight: var(--Font_normal);
  font-family: Open Sans;
  border-radius: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
}
.pan_cta_button:hover {
  background-color: var(--Blue_8_hover);
}
