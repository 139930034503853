.addition-container {
  margin: 150px 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.addition-container .left-section,
.addition-container .right-section {
  width: 472px;
  background-color: #304b7d;
  color: #fff;
  border-radius: 42px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 30px;
}

.quantityInput {
  background-color: transparent;
  color: #f9f9f9;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 10px;
  all: unset;
}

.inputBtn {
  top: 0;
  right: 0;
}

.incrementBtn,
.decrementBtn {
  width: 40px;
}

/* right-section */

.right-section h3 {
  font-size: 12px;
  color: #dadada;
  margin-bottom: 5px;
  margin-left: 5px;
}

/* input field css*/

.input_Box1 {
  width: 100%;
  /* Full width */
  height: 42px;
  font-size: 12px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #dadada;
  /* border: none; */
  border-radius: 10px;
  /* Remove default input border */
  outline: none;
  /* Remove outline on focus */
  padding: 5px 5px 5px 20px;
  /* Adjust padding as needed */
}

.input_Box1::placeholder {
  color: #ffffff;
}

.darkTxt {
  color: #dadada;
}
.toggletruetxt {
  color: #f9f9f9;
}
.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

/* switch css */

.switch-section {
  padding: 10px;
  /* width: 414px; */
  border-radius: 15px;
  background-color: #667ca3;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p h4 {
  font-size: 16px;
  color: #f9f9f9;
}

p h5 {
  font-size: 12px;
  color: #f9f9f9;
}

/* CSS for switch  */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #707070;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 5px;
  bottom: 5px;
  background-color: #f9f9f9;
  transition: 0.4s;
}
.switch input:checked + .slider:before {
  left: 2px;
  bottom: 6px;
}

input:checked + .slider {
  background-color: #69b76f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #797979;
}

input:checked + .slider:before {
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 23px;
}

.slider.round:before {
  border-radius: 50%;
}

#uploadId,
#hyperlinkLBl {
  color: #dadada;
  font-size: 12px;
}

#hyperLinkid::placeholder {
  background-color: #667ca3;
  font-size: 12px;
}

@media screen {
}
